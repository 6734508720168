/**
 * OOI-specific experiments should be listed here.
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/petri/experiments/3221548
   */
  FIX_MOBILE_HEADER_STYLING: 'specs.wixBlog.mobileHeaderStyling',
  /**
   *https://bo.wix.com/petri/experiments/3272403
   */
  REMOVE_CATEGORY_TITLE: 'specs.wixBlog.RemoveCategoryTitle',
  /**
   * https://bo.wix.com/guineapig/experiments/3220067
   */
  USE_CATEGORIES_V2: 'specs.wixBlog.UseCategoriesV2',
};
